<template>
  <div>
    <div style="padding-left: 1rem;">ROLES:</div>
    <div v-if="qryRole && qryMenu && entObject">
      <div v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value">
        <cbs-user-menu-constr :role="role" :record="getRoleRecord(role)" :key-json-menu="keyJsonMenu"
                              :ent-object="entObject" :ref-object="refObject" @save="save('role', role)" />
      </div>
    </div>

    <div style="padding-left: 1rem; margin-top:2rem;">USERS:</div>
    <div v-if="qryUser && qryMenu && entObject">
      <div v-for="user in qryUser.recordset.records" :key="'user_' + user.id.value">
        <cbs-user-menu-constr :user="user" :record="getUserRecord(user)" :key-json-menu="keyJsonMenu"
                              :ent-object="entObject" :ref-object="refObject" @save="save('user', user)" />
      </div>
    </div>

    <cbs-data-object hidden objectsid="role" @load="onLoadObjRole" />
    <cbs-data-object hidden objectsid="user" @load="onLoadObjUser" />
    <cbs-data-object hidden objectsid="cbs_usermenu" @load="onLoadObjMenu" />
    <cbs-data-query hidden v-if="objMenu" :object="objMenu" @load="onLoadQryMenu" />
    <cbs-data-query hidden v-if="objRole" :object="objRole" :page-size="1000" @load="onLoadQryRole" />
    <cbs-data-query hidden v-if="objUser" :object="objUser" @load="onLoadQryUser" />
    <cbs-data-entity hidden entitysid="object" @load="onLoadEntObject" />
    <cbs-data-reference hidden v-if="entObject" hide :entityid="entObject.id" @load="onLoadRef" />

    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDataObject from '@/cubus/components/query/CbsDataObject.vue'
import CbsDataQuery from '@/cubus/components/query/CbsDataQuery.vue'
import CbsUserMenuConstr from '@/cubus/components/cbs-form/custom-forms/user-menu/CbsUserMenuConstr.vue'
import CbsDataEntity from '@/cubus/components/entity/CbsDataEntity.vue'
import CbsDataReference from '@/cubus/components/reference/CbsDataReference.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsUserMenu',
  components: {
    CbsDataEntity,
    CbsUserMenuConstr,
    CbsDataQuery,
    CbsDebug,
    CbsDataObject,
    CbsDataReference,
  },
  props: {
    objectFull: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      objMenu: null,
      qryMenu: null,
      objRole: null,
      qryRole: null,
      objUser: null,
      qryUser: null,
      keyRole: null,
      keyUsersid: null,
      keyJsonMenu: null,
      entObject: null,
      refObject: null,
    }
  },
  methods: {
    onLoadObjMenu(obj) {
      this.objMenu = obj
    },
    onLoadQryMenu(obj) {
      this.qryMenu = obj
      this.keyRole = obj.columns.find(c => c.entitysid === 'role').key
      this.keyUsersid = obj.columns.find(c => c.entitysid === 'usersid').key
      this.keyJsonMenu = obj.columns.find(c => c.entitysid === 'jsonusermenu').key
    },
    onLoadObjRole(obj) {
      this.objRole = obj
    },
    onLoadQryRole(obj) {
      this.qryRole = obj
    },
    onLoadObjUser(obj) {
      this.objUser = obj
    },
    onLoadQryUser(obj) {
      this.qryUser = obj
    },
    getRoleRecord(role) {
      if (!this.qryMenu) {
        console.log('null record', role)
        return null
      }
      const rec = this.qryMenu.recordset.records.find(r => r[this.keyRole].value === role.id.value)
      const res = rec || this.getNewRoleRecord(role)
      return res
    },
    getUserRecord(user) {
      if (!this.qryMenu) {
        console.log('null record', user)
        return null
      }
      const rec = this.qryMenu.recordset.records.find(r => r[this.keyUsersid].value === user.id.value)
      const res = rec || this.getNewUserRecord(user)
      return res
    },
    getNewRoleRecord(role) {
      const res = {}
      this.qryMenu.columns.forEach(c => {
        if (c.key !== 'actions') {
          res[c.key] = { value: null, title: null }
          res[c.key].value = c.default.value
          res[c.key].title = c.default.title
        }
      })
      res.del.value = false
      res[this.keyRole].value = role.id.value
      res[this.keyRole].title = role.name.value
      res[this.keyJsonMenu].value = this.getNewMenu()
      this.qryMenu.recordset.records.push(res)
      return res
    },
    getNewUserRecord(user) {
      const res = {}
      this.qryMenu.columns.forEach(c => {
        if (c.key !== 'actions') {
          res[c.key] = { value: null, title: null }
          res[c.key].value = c.default.value
          res[c.key].title = c.default.title
        }
      })
      res.del.value = false
      res[this.keyUsersid].value = user.id.value
      res[this.keyUsersid].title = user.name.value
      res[this.keyJsonMenu].value = this.getNewMenu()
      this.qryMenu.recordset.records.push(res)
      return res
    },
    getNewMenu() {
      return {
        items: [
          {
            title: 'New item',
            icon: 'CircleIcon',
            route: null,
            expanded: false,
            children: [],
          },
        ],
      }
    },
    onLoadEntObject(obj) {
      this.entObject = obj
    },
    onLoadRef(obj) {
      this.refObject = obj
    },
    save(mode, item) {
      const rec = mode === 'role' ? this.getRoleRecord(item) : this.getUserRecord(item)
      rec[this.keyJsonMenu].status = 'changed'
      console.log('save rec', rec)
      this.saveRecord(rec, 'role')
    },
    saveRecord(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objMenu.object.id,
            record,
          },
        },
      })
        .then(response => {
          // console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          // console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 500)
    },
    afterSave(initRec, savedRec) {
      savedRec.status = 'saved'
      this.qryMenu.columns.forEach(fld => {
        if (fld.key !== 'actions') {
          initRec[fld.key].value = savedRec[fld.key].value
          initRec[fld.key].title = savedRec[fld.key].title
        }
      })
      initRec.status = 'saved'
    },
  },
}
</script>

<style scoped>

</style>
