<template>
  <div style="width: 100%;">
    <div class="cbs-dropdown" style="width: 100%;">
      <div v-if="!isEdit" @click="setEditOn"
           class="activator"
           style="border: none; padding: 0;" :style="record.cbs_access === 'rw' ? 'cursor: pointer;' : ''">
        {{ title || '\u00a0' }}
      </div>

      <b-input-group v-if="isEdit" class="input-group-merge">
        <b-form-input v-model="work" :id="`input_${uuid}`" @keydown="onKeyPress" :placeholder="title" @blur="input_blur" />
        <b-input-group-append is-text>
          <feather-icon icon="XIcon" class="cursor-pointer" @click="clearValue()" @mouseover="isMouseOnX = true" @mouseout="isMouseOnX = false" />
        </b-input-group-append>
      </b-input-group>

      <ul v-if="menuVisible"
          :id="`menu_${uuid}`"
          class="cbs-dropdown-menu"
          @click="menu_click()"
          @keydown="onKeyPress"
          @mouseover="isMouseOnMenu = true"
          @mouseout="isMouseOnMenu = false">
        <li v-for="el in options()" :key="el.value" style="padding: 0; border-bottom: solid #eeeeee 1px;">
          <div v-if="el.disabled" class="bg-secondary bg-lighten-5" style="padding: 0.5rem;">
            {{ el.title }}
          </div>
          <div
            v-else-if="checkValue(field, el)"
            class="bg-primary bg-lighten-5 selectable"
            style="padding: 0.5rem;"
            :data-value="JSON.stringify({ value: el.value, title: el.title })"
          >
            {{ el.title && el.title !== '' ? el.title : `(${el.value})` }}
          </div>
          <div
            v-else
            class="selectable"
            :data-value="JSON.stringify({ value: el.value, title: el.title })"
            @click="selectElement(el)" style="cursor: pointer; padding: 0.5rem;">
            {{ el.title }}
          </div>
        </li>
      </ul>
      <div v-if="false">
        title: {{ title }}
      </div>
    </div>
    <cbs-debug v-if="false && isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import { BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import useCubus from '@/cubus/services/useCubus'
import nextCell from '@/@core/utils/next-cell'
import { scrollIntoViewIfNeeded } from '@/@core/utils/utils'

export default {
  name: 'CbsReferencePicker3',
  components: {
    CbsDebug,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    field: {
      type: Object,
      default: null,
    },
    variant: {
      type: String,
      default: '',
    },
    record: {
      type: Object,
      default: null,
    },
    propRef: {
      type: Array,
      default: null,
    },
    noRefresh: {
      type: Boolean,
      default: false,
    },
    noLink: {
      type: Boolean,
      default: false,
    },
    keyMode: {
      type: String,
      default: 'key',
    },
  },
  emits: ['updateCell'],
  data() {
    return {
      menuVisible: false,
      title: null,
      value: null,
      isEdit: false,
      work: null,
      uuid: null,
      isMouseOnMenu: false,
      isMouseOnX: false,
      ref: null,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
    }
  },
  watch: {
    record: {
      handler() {
        this.init()
      },
      deep: true,
    },
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.title = this.keyMode === 'sid' ? this.record[this.field.entitysid].title : this.record[this.field.key].title
      this.value = this.keyMode === 'sid' ? this.record[this.field.entitysid].value : this.record[this.field.key].value
      if (!this.propRef) this.loadRef()
    },
    checkMenuVisible() {
      setTimeout(() => {
        const el = this.currentSelectedMenuItem()
        if (el) {
          scrollIntoViewIfNeeded(el)
        }
      }, 100)
    },
    currentSelectedMenuItem() {
      const el = document.getElementById(`menu_${this.uuid}`)
      if (!el) return null
      return el.querySelector('.bg-primary')
    },
    onKeyPress(event) {
      if (event.key === 'Enter') {
        this.menuVisible = false
        this.isEdit = false
        const selected = this.currentSelectedMenuItem()
        if (selected) {
          const selectedData = JSON.parse(selected.getAttribute('data-value'))
          console.log('selectedData', selectedData)
          this.$emit('updateCell', selectedData)
        }
      }
      if (nextCell(event)) {
        this.menuVisible = false
        this.isEdit = false
        this.checkMenuVisible()
      }
      if (event.key === 'Escape') {
        this.menuVisible = false
        this.isEdit = false
        this.checkMenuVisible()
      }
      if (event.key === 'ArrowUp' || event.key === 'ArrowDown') {
        let direction = 1
        if (event.key === 'ArrowUp') direction = -1
        console.log('Trying up')
        const selected = this.currentSelectedMenuItem()
        if (selected) {
          const li = selected.parentElement
          let nextMenuItem
          if (direction === 1) {
            nextMenuItem = li.nextElementSibling.querySelector('div')
          } else {
            nextMenuItem = li.previousElementSibling.querySelector('div')
          }
          if (nextMenuItem) {
            // if nextMenuItem is disabled, try next
            if (nextMenuItem.classList.contains('bg-secondary')) {
              if (direction === 1) {
                nextMenuItem = nextMenuItem.parentElement.nextElementSibling.querySelector('div')
              } else {
                nextMenuItem = nextMenuItem.parentElement.previousElementSibling.querySelector('div')
              }
              if (!nextMenuItem) return
              if (nextMenuItem.classList.contains('bg-secondary')) {
                return
              }
            }
            selected.classList.remove('bg-primary', 'bg-lighten-5')
            nextMenuItem.classList.add('bg-primary', 'bg-lighten-5')
            scrollIntoViewIfNeeded(nextMenuItem)
          }
        } else {
          const menu = document.getElementById(`menu_${this.uuid}`)
          console.log('menu', menu)
          // find selectable
          const firstMenuItem = menu.querySelector('.selectable')
          console.log('firstMenuItem1', firstMenuItem)
          if (firstMenuItem) {
            firstMenuItem.classList.add('bg-primary', 'bg-lighten-5')
            scrollIntoViewIfNeeded(firstMenuItem)
          }
        }
      }
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible
      this.work = this.title
      this.isEdit = true
      // this.menuVisible = true
      this.checkMenuVisible()
    },
    onBlur() {
      // console.log('onBlur')
      this.menuVisible = false
      this.isEdit = false
      console.log('setEditOff', this)
      this.checkMenuVisible()
    },
    selectElement(el) {
      // console.log('selectElement', el)
      this.menuVisible = false
      this.isEdit = false
      this.isMouseOnX = false
      this.isMouseOnMenu = false
      this.$emit('updateCell', el)
      this.checkMenuVisible()
    },
    onFocus() {
      // console.log('onfocus')
      this.menuVisible = true
      this.checkMenuVisible()
    },
    setEditOn() {
      if (this.record.cbs_access === 'ro') return
      if (this.record.cbs_sys_access === 'ro') return
      this.menuVisible = true
      this.isEdit = true
      this.$nextTick(() => {
        document.getElementById(`input_${this.uuid}`).focus()
      })
      this.checkMenuVisible()
    },
    setEditOff() {
      this.isEdit = false
      console.log('setEditOff', this)
    },
    menu_click() {
      // console.log('menu_click')
    },
    input_blur(event) {
      // console.log('input_blur')
      // console.log('event', $event)
      // this.menuVisible = false
      if (this.isMouseOnX) {
        this.clearValue()
      } else if (this.isMouseOnMenu) {
        // console.log('Mouse is On Menu')
      } else {
        this.isEdit = false
        this.menuVisible = false
        this.work = null
      }
      this.checkMenuVisible()
    },
    options() {
      if (this.work) return (this.propRef || this.ref).filter(el => this.filterBy(el.title, this.work))
      return this.propRef
    },
    filterBy(title, search) {
      const pattern = `^${search
        .replace(new RegExp(' ', 'gi'), '.*')
        .replace(new RegExp('\\.\\*\\.\\*', 'gi'), ' ')}.*`
      const re = new RegExp(pattern, 'gi')
      return re.test(title || '')
    },
    clearValue() {
      this.title = null
      this.value = null
      this.isEdit = false
      this.menuVisible = false
      this.isMouseOnX = false
      this.isMouseOnMenu = false
      this.$emit('updateCell', { value: null, title: null })
      this.checkMenuVisible()
    },
    async loadRef() {
      const ref = await useCubus.loadRef(this, { entityid: this.field.entityid })
      if (ref) {
        this.ref = ref
      }
    },
    checkValue(field, el) {
      if (this.keyMode === 'sid') {
        return this.record[field.entitysid].value === el.value
      }
      return this.record[field.key].value === el.value
    },
  },
}
</script>

<style scoped>
.cbs-dropdown {
  position: relative;
  display: inline-block;
}

.cbs-dropdown-button {
  background-color: #3498db;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.cbs-dropdown-button:hover {
  background-color: #2980b9;
}

.cbs-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: white;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 0;
  margin: 0;
  width: 200%;
  z-index: 999;
  overflow: scroll;
  max-height: 30vh;
}

.cbs-dropdown-menu li {
  padding: 0.5rem;
  text-align: left;
}

.cbs-dropdown-menu li:hover {
  background-color: #f1f1f1;
}
</style>
