import { render, staticRenderFns } from "./CbsCashRequestPeriod.vue?vue&type=template&id=20f84641&scoped=true"
import script from "./CbsCashRequestPeriod.vue?vue&type=script&lang=js"
export * from "./CbsCashRequestPeriod.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20f84641",
  null
  
)

export default component.exports