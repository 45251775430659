export default {
  // Endpoints
  loginEndpoint: '/api/login',
  forgotEndpoint: '/api/forgot',
  forgotEndpointSetNew: '/api/forgot-set-new',
  secondFactorEndpoint: '/api/second-factor',
  registerEndpoint: '/api/register',
  refreshEndpoint: '/jwt/refresh-token',
  logoutEndpoint: '/api/logout',
  whoamiEndpoint: '/api/whoami',
  changepassEndpoint: '/api/changepassword',
  queryEndpoint: '/api/qry',
  uploadEndpoint: '/api/upload',
  uploadchunkEndpoint: '/api/uploadchunk',
  downloadEndpoint: '/api/download',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  storageCubusTokenKeyName: 'cubusToken',

  // headers
  headers: {
    'Access-Control-Allow-Origin': '*',
  },
}
