import Vue from 'vue'
import axios from 'axios'
import config from './config.json'

const axiosIns = axios.create({
  baseURL: config.baseURL,
  timeout: 0,
})

Vue.prototype.$http = axiosIns

export default axiosIns
