import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import cubusRoutes from '@/cubus/route/cubus_routes'
import useJwt from '@/cubus/jwt/useJwt'
import store from '@/store/index'
import apps from './routes/apps'
import dashboard from './routes/dashboard'
import uiElements from './routes/ui-elements/index'
import pages from './routes/pages'
import chartsMaps from './routes/charts-maps'
import formsTable from './routes/forms-tables'
import others from './routes/others'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard-cubus' } },
    ...apps,
    ...dashboard,
    ...pages,
    ...chartsMaps,
    ...formsTable,
    ...uiElements,
    ...others,
    ...cubusRoutes,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  console.log('before each', to)
  if (to.name === 'auth-login') return next()
  if (to.name === 'auth-register') return next()
  if (to.name === 'auth-forgot-password') return next()
  if (to.name === 'auth-forgot-password-set-new') return next()
  const token = localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName)
  // console.log('token', token)
  if (token) {
    useJwt.whoami({ token })
      .then(response => {
        console.log('whoami response', response)
        if (response.data.session) {
          store.commit('cubus-store/SET_SESSION', response.data.session)
          return next()
        }
        return next({ name: 'auth-login' })
      })
      .catch(error => {
        console.log('whoami error', error)
        return next({ name: 'auth-login' })
      })
    return next()
  }
  return next({ name: 'auth-login' })
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  /*
  Vue.nextTick(() => {
    document.title = to.meta.title ? `Cubus - ${to.meta.title}` : 'Cubus - Business Automation Platform'
  })
  */
})

export default router
