<template>
  <div>
    <div style="margin-bottom: 1rem; margin-left: 1rem;">
      <table>
        <tbody>
          <tr>
            <td style="padding-right: 2rem;">
              <b-button variant="danger" size="sm" @click="mountPaycal()">
                <feather-icon icon="RefreshCwIcon"/> (Пере)Сборка ПК
              </b-button>
              <b-button variant="primary" size="sm" @click="recalcSaldo()" style="margin-left:0.3rem;">
                <feather-icon icon="RefreshCwIcon"/> Пересчет Сальдо
              </b-button>
              <b-button :variant="isModeCopy ? 'primary' : 'outline-primary'" size="sm" @click="isModeCopy = !isModeCopy" style="margin-left:0.3rem;">
                <feather-icon :icon="isModeCopy ? 'ChevronDownIcon' : 'ChevronRightIcon'"/> Сделать Копию
              </b-button>
            </td>
            <td>Курсы валют:</td>
            <td style="padding: 0.2rem 0.5rem 0.2rem 1rem;">USD:</td>
            <td class="border-secondary" style="padding: 0.2rem 1rem 0.2rem 1rem; min-width: 5rem;">
              <cbs-budreq-cell v-if="qryRate"
                               :record="getRateRecord('USD')"
                               key-value="currency_rate"
                               value-type="number"
                               access="rw"
                               style="text-align: center; min-width: 5rem;"
                               no-next-cell
                               @edit="onEditRate(getRateRecord('USD'), $event)"/>
            </td>
            <td style="padding: 0.2rem 0.5rem 0.2rem 1rem;">EUR:</td>
            <td class="border-secondary" style="padding: 0.2rem 1rem 0.2rem 1rem; min-width: 5rem;">
              <cbs-budreq-cell v-if="qryRate"
                               :record="getRateRecord('EUR')"
                               key-value="currency_rate"
                               value-type="number"
                               access="rw"
                               style="text-align: center; min-width: 5rem;"
                               no-next-cell
                               @edit="onEditRate(getRateRecord('EUR'), $event)"/>
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <cbs-collapse :trigger="isModeCopy">
                <div class="border-secondary" style="border-radius: 0.5rem; padding: 0.5rem; margin-top:0.5rem;">
                  <table class="table">
                    <thead style="text-align: center;">
                      <tr>
                        <th>Сценарий-Источник</th>
                        <th>Сценарий-Приемник</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div style="text-align: center;">
                            <b>{{ scenario.name.value }}</b> ({{ scenario.id.value }})
                          </div>
                        </td>
                        <td>
                          <b-form-radio v-model="copyType" value="new" style="margin-bottom: 1rem;">
                            Новый
                          </b-form-radio>
                          <cbs-collapse :trigger="copyType === 'new'">
                            <b-form-input v-model="newScenarioName" placeholder="Наименование нового сценария" style="margin-top: 0.5rem; margin-bottom: 1rem;"/>
                          </cbs-collapse>
                          <b-form-radio v-model="copyType" value="exist" style="margin-bottom: 1rem;">
                            Существующий
                          </b-form-radio>
                          <cbs-collapse :trigger="copyType === 'exist'">
                            <cbs-reference-picker :record="recScenario" :field="fldScenario" :prop-ref="refScenarioCopy()" variant="primary" />
                          </cbs-collapse>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <b-button variant="primary" size="sm" @click="copyScenario()" :disabled="!isCopyAllowed()">
                            <feather-icon icon="CopyIcon"/> Скопировать Сценарий
                          </b-button>
                        </td>
                        <td>
                          <p class="text-secondary">
                            Внимание! При копировании сценария все имеющиеся данные за период в сценарии-приемнике будут удалены.
                          </p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </cbs-collapse>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="dataReady" style="overflow: scroll; max-height: 70vh; min-height: 20rem;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th rowspan="2" class="corner1" :id="`corner1_${uuid}`">Код</th>
            <th rowspan="2" class="corner2" :id="`corner2_${uuid}`" :style="styleStickCol2()">Наименование</th>
            <th class="th-row1" :id="`th_stick_row_${uuid}`">Контрагент</th>
            <th class="th-row1">Подразделение</th>
            <th class="th-row1">Валюта</th>
            <th class="th-row1">Статус</th>
            <!--<th colspan="3" class="th-row1" style="text-align: center;">{{ weekTitle(1).start }}<br>{{ weekTitle(1).finish }}</th>-->
            <th v-for="n in periodNums" :key="n" colspan="3" class="th-row1" style="text-align: center; border-left: solid gray 1px; border-right: solid gray 1px;">
              {{ weekTitle(n).start }}<br>{{ weekTitle(n).finish }}
            </th>
          </tr>
          <tr>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('firm')" :reference="refFirm()" :condition="getCondition('firm')" @apply="applyFilter()" />
            </th>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('business_unit')" :reference="refUnit()" :condition="getCondition('business_unit')" @apply="applyFilter()" />
            </th>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('currency')" :reference="refCur()" :condition="getCondition('currency')" @apply="applyFilter()" />
            </th>
            <th class="flt0 th-row2">
              <cbs-budreq-filter :column="getColumn('paycal_status')" :reference="refStatus()" :condition="getCondition('paycal_status')" @apply="applyFilter()" />
            </th>
            <th v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="th-row2"
                :style="crs.curName === 'KZT' ? 'border-left: solid gray 1px;' : crs.curName === 'EUR' ? 'border-right: solid gray 1px;' : ''"
            >
              {{ crs.curName }}
            </th>
          </tr>
        </thead>
        <tbody v-for="item in paycalItems()" :key="item.id.value">
          <tr>
            <td class="cbs-cell stick-col1 table-light">
              <div style="display: flex;" :style="'margin-left: ' + (item.parentlevel.value - 1) * 0.75 + 'rem;'">
                <span v-if="paycalType(item) === 'folder'" style="cursor: pointer;" @click="expandPaycal(item)" @dblclick="expandAllPaycal(item)">
                  <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" />
                </span>
                <span v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" style="cursor: pointer;" @click="expandLines(item)">
                  <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusCircleIcon' : 'PlusCircleIcon'" />
                </span>
                &nbsp;&nbsp;{{ item.code.value }}
              </div>
            </td>
            <td class="cbs-cell stick-col2 table-light" :style="styleStickCol2()">
              <div :style="'margin-left: ' + (item.parentlevel.value - 1) * 1 + 'rem;'">
                {{ item.name.value }}&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill
                         variant="light-secondary" title="Количество заявок"
                >
                  {{ requestLinesCount(item) }}
                </b-badge>&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill variant="light-success"
                         title="Добавить строку" style="cursor: pointer;" @click="addLine(item)">+</b-badge>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cell-num"
                :class="(item.sid.value === 'saldo_out' || item.sid.value === 'saldo_in') && getTotalAmount(item, crs.periodNum, crs.curName) < 0 ? 'bg-danger text-light' : ''"
                :style="crs.curName === 'KZT' ? 'border-left: solid gray 1px;' : crs.curName === 'EUR' ? 'border-right: solid gray 1px;' : ''"
            >
              <div>{{ amountToStr(getTotalAmount(item, crs.periodNum, crs.curName)) }}</div>
            </td>
          </tr>
          <tr v-for="line in requestLines(item)" :key="line.sid.value">
            <td class="cbs-cell stick-col1 table-light" style="text-align: right;">{{ line.cash_request.value }}</td>
            <td class="cbs-cell stick-col2 table-light" :style="styleStickCol2()" style="display: flex;">
              <cbs-budreq-cell align-left :record="line" key-value="descr" @edit="onUpdateCell(line, 'descr', $event)" access="rw" />
              <span>
                <b-badge v-if="line.cash_request_sid.value.startsWith('dummy_')" pill variant="light-danger"
                         title="Удалить" style="cursor: pointer;" @click="deleteLine(line)">
                  <feather-icon icon="TrashIcon" />
                </b-badge>
              </span>
            </td>
            <td class="cbs-cell">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'firm')"
                                     :prop-ref="refFirm()" @updateCell="onUpdateCell(line, 'firm', $event)"
                                     :key-mode="'sid'"
              />
              <span v-else>{{ line.firm.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'business_unit')"
                                     :prop-ref="refUnit()" @updateCell="onUpdateCell(line, 'business_unit', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.business_unit.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'currency')"
                                     :prop-ref="refCur()" @updateCell="onUpdateCell(line, 'currency', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.currency.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              {{ line.paycal_status.title }}
            </td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cbs-cell" :style="styleAmountCell(item, line, crs)">
              <cbs-budreq-cell v-if="line.currency.title === crs.curName"
                               :record="getRecord(line, crs.periodNum, crs.curName)"
                               key-value="curamount"
                               value-type="number"
                               :access="lineAccess(crs, line, item)"
                               style="text-align: right;"
                               @edit="onEdit(getRecord(line, crs.periodNum, crs.curName), $event)"
              />
            </td>
          </tr>
        </tbody>
        <tbody>
          <tr style="border-left: none; border-right: none;">
            <td colspan="24" style="padding-top: 2rem; border-left: none; border-right: none; border-bottom: solid black 1px;"></td>
          </tr>
          <tr style="border-left: none; border-right: none; background-color: lightyellow;">
            <td colspan="24" style="border-left: none; border-right: none;"></td>
          </tr>
        </tbody>
        <tbody v-for="item in depositItems()" :key="item.id.value" style="background-color: lightyellow;">
          <tr>
            <td class="cbs-cell stick-col1" style="background-color: lightyellow;">
              <div style="display: flex;" :style="'margin-left: ' + (item.parentlevel.value - 1) * 0.75 + 'rem;'">
                  <span v-if="paycalType(item) === 'folder'" style="cursor: pointer;" @click="expandPaycal(item)" @dblclick="expandAllPaycal(item)">
                    <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusSquareIcon' : 'PlusSquareIcon'" />
                  </span>
                <span v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" style="cursor: pointer;" @click="expandLines(item)">
                    <feather-icon size="16" :icon="item.cbs_expanded ? 'MinusCircleIcon' : 'PlusCircleIcon'" />
                  </span>
                &nbsp;&nbsp;{{ item.code.value }}
              </div>
            </td>
            <td class="cbs-cell stick-col2" style="background-color: lightyellow;" :style="styleStickCol2()">
              <div :style="'margin-left: ' + (item.parentlevel.value - 1) * 1 + 'rem;'">
                {{ item.name.value }}&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill
                         variant="light-secondary" title="Количество заявок"
                >
                  {{ requestLinesCount(item) }}
                </b-badge>&nbsp;
                <b-badge v-if="paycalType(item) === 'units' || paycalType(item) === 'treasury'" pill variant="light-success"
                         title="Добавить строку" style="cursor: pointer;" @click="addLine(item)">+</b-badge>
              </div>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cell-num"
                :class="(item.sid.value === 'saldo_out' || item.sid.value === 'saldo_in') && getTotalAmount(item, crs.periodNum, crs.curName) < 0 ? 'bg-danger text-light' : ''"
                :style="crs.curName === 'KZT' ? 'border-left: solid gray 1px;' : crs.curName === 'EUR' ? 'border-right: solid gray 1px;' : ''"
            >
              <div>{{ amountToStr(getTotalAmount(item, crs.periodNum, crs.curName)) }}</div>
            </td>
          </tr>
          <tr v-for="line in requestLines(item)" :key="line.sid.value">
            <td class="cbs-cell stick-col1" style="text-align: right; background-color: lightyellow;">{{ line.cash_request.value }}</td>
            <td class="cbs-cell stick-col2" :style="styleStickCol2()" style="display: flex; background-color: lightyellow;">
              <cbs-budreq-cell align-left :record="line" key-value="descr" @edit="onUpdateCell(line, 'descr', $event)" access="rw" />
              <span>
                  <b-badge v-if="line.cash_request_sid.value.startsWith('dummy_')" pill variant="light-danger"
                           title="Удалить" style="cursor: pointer;" @click="deleteLine(line)">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                </span>
            </td>
            <td class="cbs-cell">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'firm')"
                                     :prop-ref="refFirm()" @updateCell="onUpdateCell(line, 'firm', $event)"
                                     :key-mode="'sid'"
              />
              <span v-else>{{ line.firm.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'business_unit')"
                                     :prop-ref="refUnit()" @updateCell="onUpdateCell(line, 'business_unit', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.business_unit.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              <cbs-reference-picker3 v-if="line.cash_request_sid.value.startsWith('dummy_')"
                                     :record="line"
                                     :field="qryPaycal.columns.find(c => c.entitysid === 'currency')"
                                     :prop-ref="refCur()" @updateCell="onUpdateCell(line, 'currency', $event)"
                                     style="text-align: center;" :key-mode="'sid'"
              />
              <span v-else>{{ line.currency.title }}</span>
            </td>
            <td class="cbs-cell" style="text-align: center;">
              {{ line.paycal_status.title }}
            </td>
            <td v-for="crs in cursor" :key="`${crs.periodNum}_${crs.curName}`" class="cbs-cell" :style="styleAmountDepositCell(item, line, crs)">
              <cbs-budreq-cell v-if="line.currency.title === crs.curName"
                               :record="getRecord(line, crs.periodNum, crs.curName)"
                               key-value="curamount"
                               value-type="number"
                               :access="lineAccess(crs, line, item)"
                               style="text-align: right;"
                               @edit="onEdit(getRecord(line, crs.periodNum, crs.curName), $event)"
              />
            </td>
          </tr>
        </tbody>
        <tbody style="background-color: lightyellow;">
          <tr>
            <td class="cbs-cell stick-col1" style="background-color: lightyellow;">6</td>
            <td class="cbs-cell stick-col2" style="background-color: lightyellow;" :style="styleStickCol2()">Всего эквивалент в USD на конец периода</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="per in periodNums" :key="per" colspan="3" style="text-align: center; border-right: solid gray 1px;">
              {{ getTotalUsd(per).toLocaleString() }}
            </td>
          </tr>
          <tr>
            <td class="cbs-cell stick-col1" style="background-color: lightyellow;">7</td>
            <td class="cbs-cell stick-col2" style="background-color: lightyellow;" :style="styleStickCol2()">Лимит на USD депозиты согласно Казначейского портфеля</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td v-for="per in periodNums" :key="per" colspan="3" style="text-align: center; border-right: solid gray 1px;" :class="getDepositShare(per) > 0.95 ? 'text-danger' : ''">
              <div>
                {{ (getDepositShare(per) * 100).toFixed(2) }}%
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <b-modal :ref="'modal_mount_' + uuid"
             cancel-variant="outline-warning"
             ok-title="Подтверждаю"
             cancel-title="Отмена"
             centered
             title="Подтверждение"
             @ok="runMount"
             @cancel="onCancelMount"
             @close="onCloseMount"
    >
      Инициирован сброс Платежного календаря.<br>Все данные кроме Запросов наличности будут удалены.<br>Вы подтверждаете?
    </b-modal>
    <b-modal :ref="'modal_copy_paycal_' + uuid"
             cancel-variant="outline-warning"
             ok-title="Подтверждаю"
             cancel-title="Отмена"
             centered
             title="Подтверждение"
             @ok="runCopyPaycal"
             @cancel="onCancelMount"
             @close="onCloseMount"
    >
      Вы подтверждаете создание копии Платежного календаря?
    </b-modal>
    <b-modal :ref="'modal_copy_paycal_ok_' + uuid"
             ok-only
             ok-title="OK"
             centered
             title="Сообщение"
             @ok="onCloseMount"
    >
      Копия Платежного календаря успешно создана.
    </b-modal>
    <cbs-debug v-if="isRoot" :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from '@/cubus/services/useCubus'
import CbsBudreqCell from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqCell.vue'
import {
  BBadge, BButton, BFormInput, BFormRadio,
} from 'bootstrap-vue'
import CbsReferencePicker3 from '@/cubus/components/reference/CbsReferencePicker3.vue'
import CbsBudreqFilter from '@/cubus/components/cbs-form/custom-forms/budget-request/CbsBudreqFilter.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsReferencePicker from '@/cubus/components/reference/CbsReferencePicker.vue'

export default {
  name: 'CbsPaycalTable',
  components: {
    CbsReferencePicker,
    CbsCollapse,
    CbsBudreqFilter,
    CbsReferencePicker3,
    CbsBudreqCell,
    CbsDebug,
    BBadge,
    BButton,
    BFormRadio,
    BFormInput,
  },
  props: {
    period: {
      type: String,
      default: null,
    },
    scenario: {
      type: Object,
      default: null,
    },
    refScenario: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      startDate: null,
      objCashreq: null,
      qryMethod: null,
      qryRefPaycal: null,
      qryCashreq: null,
      uuid: null,
      dataReady: false,
      corner1: null,
      thStickRow: null,
      qryPaycal: null,
      // qryScenario: null,
      startOfMonth: null,
      periodNums: [1, 2, 3, 4, 5, 6],
      curs: ['KZT', 'USD', 'EUR'],
      cursor: null,
      counter: 0,
      qryCfDirection: null,
      qryCurrency: null,
      qryFirm: null,
      qryUnit: null,
      qryRate: null,
      condDate: null,
      condScenario: null,
      filter: null,
      qryRefStatus: null,
      delay: { show: 1000, hide: 50 },
      isModeCopy: false,
      copyType: 'new',
      newScenarioName: `Копия ${this.scenario.name.value} ${useCubus.guid()}`,
      recScenario: {
        scenario: {
          value: null,
          title: null,
        },
      },
      fldScenario: {
        entitysid: 'scenario',
        datatypesid: 'ref',
        key: 'scenario',
      },
      destScenario: null,
    }
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
      condNotDel: useCubus.cond_notDel(),
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.initParams()
      // this.initData()
    },
    async initParams() {
      // const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const [year, month, day] = this.period.split('-').map(Number) // Преобразуем в числа
      this.startDate = new Date(year, month - 1, day)
      this.startOfMonth = new Date(this.startDate.getFullYear(), this.startDate.getMonth(), 1)
      this.cursor = this.getCursor()
      this.condDate = {
        isactive: true,
        oper: { sid: 'notless' },
        args: [
          { type: { sid: 'entitysid' }, value: 'date' },
          { type: { sid: 'value' }, value: this.dateToUTCString(this.startDate) },
        ],
      }
      await this.initData()
    },
    async initData() {
      /*
      this.qryScenario = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'scenario',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'sid' },
                  { type: { sid: 'value' }, value: 'paycal' },
                ],
              },
            ],
          },
        },
        pageSize: 100000,
      })
      */
      this.condScenario = {
        isactive: true,
        oper: { sid: 'equal' },
        args: [
          { type: { sid: 'entitysid' }, value: 'scenario' },
          { type: { sid: 'value' }, value: this.scenario.id.value },
        ],
      }
      this.filter = {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            this.condNotDel,
            this.condDate,
            this.condScenario,
          ],
        },
      }
      this.objCashreq = await useCubus.loadObject(this, {
        objectsid: 'cash_request',
      })
      this.qryMethod = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'paycal_enter_method',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryRefPaycal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'ref_paycal',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'code' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryCfDirection = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'ref_cf_direction',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryCurrency = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'currency',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryFirm = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'reffirm',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'name' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryUnit = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'business_unit',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              entity: { sid: 'name' },
              oper: { sid: 'asc' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.qryRate = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'cube_paycal_rate',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
              this.condDate,
              this.condScenario,
            ],
          },
        },
        pageSize: 100000,
      })
      this.qryRefStatus = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'ref_paycal_status',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              useCubus.cond_notDel(),
            ],
          },
        },
        pageSize: 100000,
      })
      await this.loadQryPaycal()
    },
    async loadQryPaycal() {
      this.qryPaycal = await useCubus.loadQuery(this, {
        keymode: 'sid',
        withsubrecords: true,
        objectsid: 'cube_paycal',
        filter: this.filter,
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'descr' },
            },
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'id' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.dataReady = true
      this.$nextTick(() => {
        this.corner1 = document.getElementById(`corner1_${this.uuid}`)
        this.thStickRow = document.getElementById(`th_stick_row_${this.uuid}`)
        this.stickTable()
      })
    },
    stickTable() {
      const offset1w = this.corner1.offsetWidth
      const offset1h = this.thStickRow.offsetHeight
      document.querySelectorAll('.corner2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.stick-col2').forEach(cell => { const b = cell; b.style.left = `${offset1w}px` })
      document.querySelectorAll('.th-row2').forEach(cell => { const b = cell; b.style.top = `${offset1h}px` })
    },
    paycalItems() {
      return this.qryRefPaycal.recordset.records
        .filter(r => Number(r.code.value.slice(0, 1)) < 5)
        .filter(rec => rec.parentlevel.value === 1 || rec.cbs_visible)
    },
    depositItems() {
      return this.qryRefPaycal.recordset.records
        .filter(r => r.code.value.startsWith('5'))
        .filter(rec => rec.parentlevel.value === 1 || rec.cbs_visible)
    },
    expandPaycal(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
      this.setChildrenVisible(rec, rec.cbs_expanded)
      this.$nextTick(() => this.stickTable())
    },
    setChildrenVisible(parent, visible) {
      this.qryRefPaycal.recordset.records.filter(rec => rec.parent.value === parent.id.value).forEach(rec => {
        if ('cbs_visible' in rec) {
          const b = rec
          b.cbs_visible = visible
        } else {
          this.$set(rec, 'cbs_visible', visible)
        }
        if (rec.isfolder.value === true && (rec.cbs_expanded || visible === false)) {
          this.setChildrenVisible(rec, visible)
        }
      })
    },
    expandAllPaycal(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
      this.setChildrenVisible(rec, rec.cbs_expanded)
      this.qryRefPaycal.recordset.records.filter(r => r.parent.value === rec.id.value).forEach(r => this.expandAllPaycal(r))
    },
    methodSid(paycal) {
      const method = this.qryMethod.recordset.records.find(r => r.id.value === paycal.paycal_enter_method.value)
      return method ? method.sid.value : 'none'
    },
    requestLines(paycal) {
      if ((this.paycalType(paycal) === 'units' || this.paycalType(paycal) === 'treasury') && paycal.cbs_expanded) {
        return this.qryPaycal.recordset.records.filter(r => r.paycal_item.value === paycal.id.value).reduce((set, rec) => {
          if (set.some(el => el.cash_request_sid.value === rec.cash_request_sid.value)) return set
          // добавляем к аккумулятору и возвращаем новый аккумулятор
          return [...set, rec].sort((a, b) => {
            if (a.firm.value === b.firm.value) {
              if (a.currency.value === b.currency.value) {
                if ((a.descr.value || '') === (b.descr.value || '')) {
                  return (a.descr.value || '').localeCompare(b.descr.value || '')
                }
                return (a.currency.title || '').localeCompare(b.currency.title || '')
              }
              return a.paycal_status.value - b.paycal_status.value
            }
            return (a.firm.title || '').localeCompare(b.firm.title || '')
          })
        }, [])
      }
      return []
    },
    requestLinesCount(paycal) {
      const res = this.qryPaycal.recordset.records.filter(r => r.paycal_item.value === paycal.id.value).reduce((set, rec) => {
        if (set.some(el => el.cash_request_sid.value === rec.cash_request_sid.value)) return set
        return [...set, rec] // добавляем к аккумулятору и возвращаем новый аккумулятор
      }, [])
      return res.length
    },
    expandLines(rec) {
      if (!('cbs_expanded' in rec)) {
        this.$set(rec, 'cbs_expanded', true)
        this.$set(rec, 'cbs_visible', true)
      } else {
        const b = rec
        b.cbs_expanded = !rec.cbs_expanded
      }
    },
    weekTitle(nr) {
      // const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const [year, month, day] = this.period.split('-').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (nr - 1))
      const fn = new Date(year, month - 1, day + 7 * (nr) - 1)
      // return `${this.dateToString(st)} ${this.dateToString(fn)}`
      return { start: this.dateToString(st), finish: this.dateToString(fn) }
    },
    dateToString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      return `${day}.${month}.${year}`
    },
    dateToUTCString(dt) {
      // Извлекаем компоненты
      const year = dt.getFullYear()
      const month = String(dt.getMonth() + 1).padStart(2, '0') // Месяцы начинаются с 0
      const day = String(dt.getDate()).padStart(2, '0') // Добавляем ведущий ноль
      // Форматируем строку в формате DD.MM.YYYY
      // return `${day}.${month}.${year}`
      return `${year}-${month}-${day}`
    },
    getSubRecordAmount(parentRec, periodNum, currency) {
      if (parentRec.currency.title !== currency) return null
      const strDt = this.getStrDate(periodNum)
      const subRec = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === parentRec.cash_request_sid.value && r.date.value.startsWith(strDt))
      if (subRec) {
        if (subRec.curamount.value === null || subRec.curamount.value === 0) return null
        return subRec.curamount.value.toLocaleString()
      }
      return null
    },
    getRecord(paycal, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const res = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === paycal.cash_request_sid.value && r.date.value.startsWith(strDt) && r.currency.title === currency)
      if (res) {
        // console.log('res found')
        return res
      }
      // console.log(`res not found : paycal, periodNum = ${periodNum}, strDt = ${strDt}, currency = ${currency}`)
      // this.counter += 1
      const n = this.getNewRecord(paycal, periodNum)
      // console.log('n', JSON.stringify(n))
      this.qryPaycal.recordset.records.push(n)
      return n
    },
    getRecordByPaycalItem(paycalItem, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const recHeader = this.qryPaycal.recordset.records.find(r => r.paycal_item.value === paycalItem.id.value && r.currency.title === currency)
      if (recHeader) {
        // console.log('recHeader found', recHeader.cash_request_sid.title || recHeader.cash_request_sid.value)
        const cashRequestSid = recHeader.cash_request_sid.value
        const recHeaderDate = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === cashRequestSid && r.date.value.startsWith(strDt))
        if (recHeaderDate) return recHeaderDate
        return this.getRecord(recHeader, periodNum, currency)
      }
      const res = this.qryPaycal.recordset.records.find(r => r.paycal_item.value === paycalItem.id.value && r.date.value.startsWith(strDt) && r.currency.title === currency)
      if (res) return res
      const n = this.getNewRecordByPaycalItem(paycalItem, periodNum, currency)
      this.qryPaycal.recordset.records.push(n)
      return n
    },
    getNewRecordByPaycalItem(paycalItem, periodNum, currency) {
      const strDt = this.getStrDate(periodNum)
      const cur = this.qryCurrency.recordset.records.find(r => r.name.value === currency)
      const n = {}
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(col => {
        n[col.entitysid] = {}
        n[col.entitysid].value = col.default.value
        n[col.entitysid].title = col.default.title
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = `${useCubus.guid()}`
      n.sid.title = null
      n.scenario.value = this.scenario.id.value
      n.scenario.title = this.scenario.name.value
      n.date.value = strDt
      n.date.title = null
      n.paycal_item.value = paycalItem.id.value
      n.paycal_item.title = paycalItem.name.value
      n.currency.value = cur.id.value
      n.currency.title = cur.name.value
      n.curamount.value = null
      n.curamount.title = null
      n.cash_request_sid.value = `dummy_${useCubus.guid()}`
      n.cash_request_sid.title = null
      return n
    },
    getNewRecord(paycal, periodNum) {
      const strDt = this.getStrDate(periodNum)
      const n = {}
      this.qryPaycal.columns.forEach(col => {
        if (col.key !== 'actions') {
          n[col.entitysid] = {}
          n[col.entitysid].value = paycal[col.entitysid].value
          n[col.entitysid].title = paycal[col.entitysid].title
        }
      })
      n.id.value = null
      n.id.title = null
      n.sid.value = `${useCubus.guid()}`
      n.sid.title = null
      n.date.value = strDt
      n.date.title = null
      n.curamount.value = null
      n.curamount.title = null
      return n
    },
    getStrDate(periodNum) {
      // const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
      const [year, month, day] = this.period.split('-').map(Number) // Преобразуем в числа
      const st = new Date(year, month - 1, day + 7 * (periodNum - 1))
      // return this.dateToString(st)
      return this.dateToUTCString(st)
    },
    paycalType(rec) {
      if (rec.isfolder.value === true) return 'folder'
      return this.methodSid(rec)
    },
    styleStickCol2() {
      if (this.corner1) {
        return {
          left: `${this.corner1.offsetWidth}px`,
        }
      }
      return {}
    },
    getTotalAmount(paycal, periodNum, currency) {
      if (paycal.isfolder.value === true) {
        const dir = this.getDirectionSid(paycal)
        return this.qryRefPaycal.recordset.records
          .filter(r => r.parent.value === paycal.id.value)
          .reduce((sum, rec) => sum + (dir === 'none' && this.getDirectionSid(rec) === 'outcome' ? -1 : 1) * this.getTotalAmount(rec, periodNum, currency), 0)
      }
      return this.qryPaycal.recordset.records
        .filter(r => r.paycal_item.value === paycal.id.value && r.date.value.startsWith(this.getStrDate(periodNum)) && r.currency.title === currency)
        .reduce((sum, rec) => sum + (rec.curamount.value || null), 0)
    },
    amountToStr(amount) {
      if (amount === 0) return null
      return amount.toLocaleString()
    },
    getCursor() {
      return this.periodNums.map(p => this.curs.map(c => ({ periodNum: p, curName: c }))).flat()
    },
    onEdit(rec, newValue) {
      // console.log('onEdit', rec, newValue)
      const b = rec
      b.curamount.value = newValue
      b.curamount.status = 'changed'
      this.saveRecord(rec)
      if (rec.linked_cash_request_sid.value && this.paycalItem(rec).sid.value === 'oper_out_forex') {
        this.addForex(rec)
      }
    },
    async saveRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSave(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalcSaldo()
    },
    async saveRecordWithoutRecalc(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record })
        this.afterSaveWithoutRecacl(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveWithoutRecacl(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryPaycal.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
    },
    getDirectionSid(paycal) {
      const rec = this.qryCfDirection.recordset.records.find(r => r.id.value === paycal.cf_direction.value)
      return rec ? rec.sid.value : 'none'
    },
    getSaldoOut(periodNum, currency) {
      return this.getSaldoIn(periodNum, currency) + this.qryRefPaycal.recordset.records
        .filter(r => r.parentlevel.value === 1 && r.sid.value !== 'saldo_in' && r.sid.value !== 'saldo_out' && r.sid.value !== 'deposit')
        .reduce((sum, rec) => sum + this.getTotalAmount(rec, periodNum, currency), 0)
    },
    getSaldoIn(periodNum, currency) {
      const paycal = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_in')
      if (periodNum === 1) {
        return this.getTotalAmount(paycal, periodNum, currency)
      }
      return this.getSaldoOut(periodNum - 1, currency)
    },
    styleAmountCell(paycal, line, crs) {
      const bl = { borderLeft: 'solid gray 1px' }
      const br = { borderRight: 'solid gray 1px' }
      const rw = { backgroundColor: 'lightyellow' }
      const ro = {}
      let acc = {}
      let borders = {}
      if (paycal.sid.value === 'saldo_out' || paycal.sid.value === 'deposit_saldo_out') {
        acc = ro
      } else if ((paycal.sid.value === 'saldo_in' || paycal.sid.value === 'deposit_saldo_in') && crs.periodNum > 1) {
        acc = ro
      } else if (line.currency.title === crs.curName) {
        acc = rw
      }
      if (this.lineStatus(line) === 'fact') acc = ro
      if (crs.curName === 'KZT') {
        borders = bl
      } else if (crs.curName === 'EUR') {
        borders = br
      }
      return { ...acc, ...borders }
    },
    styleAmountDepositCell(paycal, line, crs) {
      const bl = { borderLeft: 'solid gray 1px' }
      const br = { borderRight: 'solid gray 1px' }
      const rw = { backgroundColor: 'yellow' }
      const ro = {}
      let acc = {}
      let borders = {}
      if (paycal.sid.value === 'saldo_out' || paycal.sid.value === 'deposit_saldo_out') {
        acc = ro
      } else if ((paycal.sid.value === 'saldo_in' || paycal.sid.value === 'deposit_saldo_in') && crs.periodNum > 1) {
        acc = ro
      } else if (line.currency.title === crs.curName) {
        acc = rw
      }
      if (this.lineStatus(line) === 'fact') acc = ro
      if (crs.curName === 'KZT') {
        borders = bl
      } else if (crs.curName === 'EUR') {
        borders = br
      }
      return { ...acc, ...borders }
    },

    recalcSaldo_v1() {
      this.recalcSaldoOut(1)
      const pers = [2, 3, 4, 5, 6]
      pers.forEach(p => {
        this.recalcSaldoIn(p)
        this.recalcSaldoOut(p)
      })
    },
    recalcSaldoOut(periodNum) {
      this.curs.forEach(c => this.recalcSaldoOutCur(periodNum, c))
    },
    recalcSaldoIn(periodNum) {
      this.curs.forEach(c => this.recalcSaldoInCur(periodNum, c))
    },
    recalcSaldoOutCur(periodNum, currency) {
      const paycalItem = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_out')
      const rec = this.getRecordByPaycalItem(paycalItem, periodNum, currency)
      rec.curamount.value = this.getSaldoOut(periodNum, currency)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },
    recalcSaldoInCur(periodNum, currency) {
      const paycal = this.qryRefPaycal.recordset.records.find(r => r.sid.value === 'saldo_in')
      const rec = this.getRecordByPaycalItem(paycal, periodNum, currency)
      // if (!rec) return
      rec.curamount.value = this.getSaldoIn(periodNum, currency)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },

    recalcSaldo() {
      this.periodNums.forEach(p => {
        this.recalcCashSaldoPeriod(p)
        this.recalcDepositSaldoPeriod(p)
      })
    },

    addLine(paycalItem) {
      const n = this.getNewRecordByPaycalItem(paycalItem, 1, 'KZT')
      this.qryPaycal.recordset.records.push(n)
    },
    deleteLine(line) {
      this.qryPaycal.recordset.records.filter(r => r.cash_request_sid.value === line.cash_request_sid.value).forEach(r => this.deleteRecord(r))
      while (this.qryPaycal.recordset.records.some(r => r.cash_request_sid.value === line.cash_request_sid.value)) {
        this.qryPaycal.recordset.records.splice(this.qryPaycal.recordset.records.findIndex(r => r.cash_request_sid.value === line.cash_request_sid.value), 1)
      }
      this.recalcSaldo()
    },
    async deleteRecord(rec) {
      if (rec.id.value !== null && rec.id.value !== 0) {
        await useCubus.delRecord(this, { keymode: 'sid', objectsid: 'cube_paycal', record: rec })
      }
      // console.log('record count 1', this.qryPaycal.recordset.records.length)
      // const ind = this.qryPaycal.recordset.records.findIndex(r => r.cash_request_sid.value === rec.cash_request_sid.value)
      // console.log('ind', ind)
      // this.qryPaycal.recordset.records.splice(this.qryPaycal.recordset.records.indexOf(rec), 1)
      // console.log('record count 2', this.qryPaycal.recordset.records.length)
    },
    refCur() {
      return this.qryCurrency.recordset.records.filter(r => this.curs.includes(r.name.value)).map(r => ({ value: r.id.value, title: r.name.value }))
    },
    onUpdateCell(rec, key, val) {
      if (key === 'curamount') {
        const b = rec
        b[key].value = val.value
        b[key].title = val.title
        b[key].status = 'changed'
        this.saveRecord(rec)
      } else if (key === 'descr') {
        this.qryPaycal.recordset.records.filter(r => r.cash_request_sid.value === rec.cash_request_sid.value).forEach(r => {
          const b = r
          b[key].value = val
          b[key].title = null
          b[key].status = 'changed'
          this.saveRecord(r)
        })
      } else {
        this.qryPaycal.recordset.records.filter(r => r.cash_request_sid.value === rec.cash_request_sid.value).forEach(r => {
          const b = r
          b[key].value = val.value
          b[key].title = val.title
          b[key].status = 'changed'
          this.saveRecord(r)
        })
      }
    },
    paycalItem(rec) {
      return this.qryRefPaycal.recordset.records.find(r => r.id.value === rec.paycal_item.value)
    },
    addForex(rec) {
      const forexRec = this.getLinkedRecord(rec)
      if (forexRec) {
        const rate = this.getRateRecord(rec.currency.title)
        const linkedRate = this.getRateRecord(forexRec.currency.title)
        forexRec.curamount.value = rec.curamount.value * (rate.currency_rate.value / linkedRate.currency_rate.value)
        forexRec.curamount.title = null
        forexRec.curamount.status = 'changed'
        this.saveRecord(forexRec)
      }
    },
    getLinkedRecord(srcRecord) {
      const rec = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === srcRecord.linked_cash_request_sid.value && r.date.value === srcRecord.date.value)
      if (rec) return rec
      const recWoDate = this.qryPaycal.recordset.records.find(r => r.cash_request_sid.value === srcRecord.linked_cash_request_sid.value)
      if (recWoDate) {
        recWoDate.id.value = null
        recWoDate.id.title = null
        recWoDate.sid.value = null
        recWoDate.sid.title = null
        recWoDate.date.value = srcRecord.date.value
        recWoDate.date.title = srcRecord.date.title
        recWoDate.curamount.value = null
        recWoDate.curamount.title = null
        this.qryPaycal.recordset.records.push(recWoDate)
        return recWoDate
      }
      return null
    },
    refFirm() {
      return this.qryFirm.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    refUnit() {
      return this.qryUnit.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    refStatus() {
      return this.qryRefStatus.recordset.records.map(r => ({ value: r.id.value, title: r.name.value }))
    },
    getRateRecord(currency) {
      const rec = this.qryRate.recordset.records.find(r => r.currency.title === currency)
      if (rec) return rec
      const newrec = this.getNewRateRecord(currency)
      this.qryRate.recordset.records.push(newrec)
      return newrec
    },
    getNewRateRecord(currency) {
      const n = {}
      this.qryRate.columns.filter(c => c.key !== 'actions').forEach(col => {
        n[col.entitysid] = {}
        n[col.entitysid].value = col.default.value
        n[col.entitysid].title = col.default.title
      })
      const cur = this.qryCurrency.recordset.records.find(r => r.name.value === currency)
      n.scenario.value = this.scenario.id.value
      n.scenario.title = this.scenario.name.value
      if (cur) {
        n.currency.value = cur.id.value
        n.currency.title = cur.name.value
      }
      n.date.value = this.period
      n.currency_rate.value = 1
      return n
    },
    onEditRate(rec, newValue) {
      const b = rec
      b.currency_rate.value = newValue
      b.currency_rate.status = 'changed'
      this.saveRateRecord(rec)
    },
    async saveRateRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'cube_paycal_rate', record })
        this.afterSaveRate(record, saved)
      } catch (e) { console.error(e) }
    },
    afterSaveRate(initRec, savedRec) {
      const i = initRec
      const s = savedRec
      s.status = 'saved'
      this.qryRate.columns.filter(c => c.key !== 'actions').forEach(fld => {
        i[fld.entitysid].value = savedRec[fld.entitysid].value
        i[fld.entitysid].title = savedRec[fld.entitysid].title
      })
      i.status = 'saved'
      this.recalcForex()
    },
    recalcForex() {
      this.qryPaycal.recordset.records.filter(r => r.linked_cash_request_sid.value && this.paycalItem(r).sid.value === 'oper_out_forex').forEach(r => this.addForex(r))
      this.recalcSaldo()
    },
    mountPaycal() {
      this.$refs[`modal_mount_${this.uuid}`].show()
    },
    async runMount() {
      // console.log('runMount')
      await useCubus.runMount(this, {
        objectsid: 'mount_cube_paycal',
        keymode: 'sid',
        record: {
          start: { value: this.period, title: null },
          finish: { value: this.weekTitle(6).finish, title: null },
          scenario: { value: this.scenario.id.value, title: this.scenario.name.value },
        },
      })
      await this.initParams()
      console.log('init done')
      this.recalcSaldo()
    },
    onCancelMount() {
      console.log('onCancelMount')
    },
    onCloseMount() {
      console.log('onCloseMount')
    },
    getColumn(key) {
      return this.qryPaycal.columns.find(c => c.entitysid === key)
    },
    getCondition(key) {
      const col = this.getColumn(key)
      if (!col) return null
      const cond = this.filter.node.conds.find(c => c.args[0].value === col.entityid)
      if (cond) return cond
      const newCond = this.getNewCondition(col)
      this.filter.node.conds.push(newCond)
      return newCond
    },
    getNewCondition(col) {
      const operSid = col.datatype === 'string' ? 'contain' : 'equal'
      return {
        isactive: false,
        oper: { sid: operSid },
        args: [
          { type: { sid: 'entity' }, value: col.entityid },
          { type: { sid: 'value' }, value: null },
        ],
      }
    },
    applyFilter() {
      this.loadQryPaycal()
    },
    lineAccess(crs, line, paycal) {
      if (line.sid.value === 'saldo_out' || (line.sid.value === 'saldo_in' && crs.periodNum > 1)) return 'ro'
      if (line.sid.value === 'deposit_saldo_out' || (line.sid.value === 'deposit_saldo_in' && crs.periodNum > 1)) return 'ro'
      if (this.lineStatus(line) === 'fact') return 'ro'
      if (paycal.sid.value === 'saldo_out' || paycal.sid.value === 'deposit_saldo_out') return 'ro'
      if ((paycal.sid.value === 'saldo_in' || paycal.sid.value === 'deposit_saldo_in') && crs.periodNum > 1) return 'ro'
      return 'rw'
    },
    lineStatus(line) {
      const stat = this.qryRefStatus.recordset.records.find(r => r.id.value === line.paycal_status.value)
      if (stat) return stat.sid.value
      return null
    },
    copyScenario() {
      this.$refs[`modal_copy_paycal_${this.uuid}`].show()
    },
    async runCopyPaycal() {
      console.log('runCopyPaycal')
      const dest = { value: null, title: null }
      if (this.copyType === 'new') {
        const newScn = {
          id: { value: null, title: null },
          sid: { value: null, title: null },
          name: { value: this.newScenarioName, title: null },
          istreasury: { value: true, title: null },
        }
        const savedScn = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'scenario', record: newScn })
        console.log(`savedScn = ${JSON.stringify(savedScn)}`)
        this.newScenarioName = `Копия ${this.scenario.name.value} ${useCubus.guid()}`
      } else {
        dest.value = this.recScenario.scenario.value
        dest.title = this.recScenario.scenario.title
      }
      console.log(`dest = ${JSON.stringify(dest)}`)
      const r = {
        start: { value: this.period, title: null },
        finish: { value: this.weekTitle(6).finish, title: null },
        sourcescenario: { value: this.scenario.id.value, title: this.scenario.name.value },
        destscenario: { value: dest.value, title: dest.title },
      }
      console.log(`r = ${JSON.stringify(r)}`)
      await useCubus.runMount(this, {
        objectsid: 'mount_copy_paycal',
        keymode: 'sid',
        record: r,
      })
      this.$refs[`modal_copy_paycal_ok_${this.uuid}`].show()
    },
    refScenarioCopy() {
      if (!this.refScenario) return []
      return this.refScenario.filter(r => r.value !== this.scenario.id.value)
    },
    isCopyAllowed() {
      return (this.copyType === 'new' && this.newScenarioName && this.newScenarioName !== '') || (this.copyType === 'exist' && this.recScenario.scenario.value)
    },

    recalcCashSaldo() {
      [1, 2, 3, 4, 5, 6].forEach(p => {
        this.recalcCashSaldoPeriod(p)
      })
    },
    recalcCashSaldoPeriod(periodNum) {
      this.curs.forEach(c => {
        this.recalcCashSaldoCur(periodNum, c)
      })
    },
    recalcCashSaldoCur(periodNum, curName) {
      this.recalcCashSaldoIn(periodNum, curName)
      this.recalcCashSaldoOut(periodNum, curName)
    },
    recalcCashSaldoIn(periodNum, curName) {
      const paycal = this.getPaycalItem('saldo_in')
      const rec = this.getRecordByPaycalItem(paycal, periodNum, curName)
      rec.curamount.value = this.getCashSaldoIn(periodNum, curName)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },
    recalcCashSaldoOut(periodNum, curName) {
      const paycal = this.getPaycalItem('saldo_out')
      if (!paycal) {
        console.log('paycal saldo_out not found')
        return
      }
      const rec = this.getRecordByPaycalItem(paycal, periodNum, curName)
      rec.curamount.value = this.getCashSaldoOut(periodNum, curName)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },

    getCashSaldoIn(periodNum, curName) {
      if (periodNum === 1) return this.getInitCashSaldoIn(curName)
      return this.getCashSaldoOut(periodNum - 1, curName)
    },
    getInitCashSaldoIn(curName) {
      return this.getPlainTotal('saldo_in', 1, curName)
    },
    getCashSaldoOut(periodNum, curName) {
      return this.getCashSaldoIn(periodNum, curName) + this.getCashTurnover(periodNum, curName)
    },
    getCashTurnover(periodNum, curName) {
      return this.qryPaycal.recordset.records
        .filter(r => r.date.value.startsWith(this.getStrDate(periodNum)) && r.currency.title === curName)
        .filter(r => {
          const paycal = this.qryRefPaycal.recordset.records.find(p => p.id.value === r.paycal_item.value)
          const sid = paycal.sid.value
          return sid !== 'saldo_in' && sid !== 'saldo_out' && sid !== 'deposit_saldo_in' && sid !== 'deposit_saldo_out'
        })
        .reduce((sum, r) => sum + (r.curamount.value || 0) * this.getPaycalKoef(this.getPaycalItemById(r.paycal_item.value)), 0)
    },

    recalcDepositSaldo() {
      [1, 2, 3, 4, 5, 6].forEach(p => {
        this.recalcDepositSaldoPeriod(p)
      })
    },
    recalcDepositSaldoPeriod(periodNum) {
      this.curs.forEach(c => {
        this.recalcDepositSaldoCur(periodNum, c)
      })
    },
    recalcDepositSaldoCur(periodNum, curName) {
      this.recalcDepositSaldoIn(periodNum, curName)
      this.recalcDepositSaldoOut(periodNum, curName)
    },
    recalcDepositSaldoIn(periodNum, curName) {
      const paycal = this.getPaycalItem('deposit_saldo_in')
      const rec = this.getRecordByPaycalItem(paycal, periodNum, curName)
      rec.curamount.value = this.getDepositSaldoIn(periodNum, curName)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },
    recalcDepositSaldoOut(periodNum, curName) {
      const paycal = this.getPaycalItem('deposit_saldo_out')
      const rec = this.getRecordByPaycalItem(paycal, periodNum, curName)
      rec.curamount.value = this.getDepositSaldoOut(periodNum, curName)
      rec.curamount.status = 'changed'
      this.saveRecordWithoutRecalc(rec)
    },

    getDepositSaldoIn(periodNum, curName) {
      if (periodNum === 1) return this.getInitDepositSaldoIn(curName)
      return this.getDepositSaldoOut(periodNum - 1, curName)
    },
    getDepositSaldoOut(periodNum, curName) {
      return this.getDepositSaldoIn(periodNum, curName) + this.getDepositTurnover(periodNum, curName)
    },
    getDepositTurnover(periodNum, curName) {
      return this.getTurnover(this.getPaycalItem('inv_out_deposit'), periodNum, curName)
        - this.getTurnover(this.getPaycalItem('inv_in_deposit'), periodNum, curName)
    },
    getInitDepositSaldoIn(curName) {
      return this.getPlainTotal('deposit_saldo_in', 1, curName)
    },

    getPaycalItem(sid) {
      return this.qryRefPaycal.recordset.records.find(r => r.sid.value === sid)
    },
    getPaycalItemById(id) {
      return this.qryRefPaycal.recordset.records.find(r => r.id.value === id)
    },
    getPaycalChildren(paycal) {
      return this.qryRefPaycal.recordset.records.filter(r => r.parent.value === paycal.id.value)
    },
    getPaycalKoef(paycal) {
      const dir = this.getDirectionSid(paycal)
      if (dir === 'outcome') return -1
      return 1
    },

    getPlainRecords(paycal, periodNum, curName) {
      return this.qryPaycal.recordset.records
        .filter(r => r.paycal_item.value === paycal.id.value
          && r.date.value.startsWith(this.getStrDate(periodNum))
          && r.currency.title === curName)
    },
    getPlainTotal(paycalSid, periodNum, curName) {
      const paycal = this.getPaycalItem(paycalSid)
      return this.getPlainRecords(paycal, periodNum, curName)
        .reduce((sum, rec) => sum + (rec.curamount.value || 0), 0)
    },
    getTurnover(paycal, periodNum, curName) {
      const dir = this.getDirectionSid(paycal)
      if (paycal.isfolder.value) {
        if (dir === 'none') {
          return this.getPaycalChildren(paycal)
            .reduce((sum, child) => sum + this.getTurnover(child, periodNum, curName) * this.getPaycalKoef(child), 0)
        }
        return this.getPaycalChildren(paycal)
          .reduce((sum, child) => sum + this.getTurnover(child, periodNum, curName), 0)
      }
      return this.qryPaycal.recordset.records
        .filter(r => r.paycal_item.value === paycal.id.value && r.date.value.startsWith(this.getStrDate(periodNum)) && r.currency.title === curName)
        .reduce((sum, rec) => sum + (rec.curamount.value || 0), 0)
    },

    getTotalUsd(periodNum) {
      return this.getTotalCashUsd(periodNum) + this.getTotalDepositUsd(periodNum)
    },
    getTotalCashUsd(periodNum) {
      const usdRate = this.getRateRecord('USD').currency_rate.value
      const eurRate = this.getRateRecord('EUR').currency_rate.value
      return this.getCashSaldoOut(periodNum, 'USD')
        + (this.getCashSaldoOut(periodNum, 'EUR') * eurRate) / usdRate
        + this.getCashSaldoOut(periodNum, 'KZT') / usdRate
    },
    getTotalDepositUsd(periodNum) {
      const usdRate = this.getRateRecord('USD').currency_rate.value
      const eurRate = this.getRateRecord('EUR').currency_rate.value
      return this.getDepositSaldoOut(periodNum, 'USD')
        + (this.getDepositSaldoOut(periodNum, 'EUR') * eurRate) / usdRate
        + this.getDepositSaldoOut(periodNum, 'KZT') / usdRate
    },
    getDepositShare(periodNum) {
      return this.getTotalDepositUsd(periodNum) / this.getTotalUsd(periodNum)
    },
  },
}
</script>

<style scoped>
.corner1 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 3;
}
.corner2 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 3;
}
.th-row1 {
  padding: 0.5rem;
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 2;
}
.th-row2 {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: center;
  position: sticky;
  z-index: 2;
}
.stick-col1 {
  position: sticky;
  left: 0;
  z-index: 1;
}
.stick-col2 {
  position: sticky;
  z-index: 1;
}
.cell-num {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  text-align: right;
}
.cbs-cell {
  padding: 0.5rem 0.5rem 0.5rem 0.5rem;
}
.th-line2 {
  position: sticky;
  z-index: 2;
}
.flt0 {
  text-transform: none;
  font-weight: normal;
}
</style>
