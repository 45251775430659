<template>
<div>
  <div style="margin-bottom: 1rem;">
    <span style="cursor: pointer;" @click="expanded = !expanded">
      <feather-icon :icon="expanded ? 'ChevronDownIcon' : 'ChevronRightIcon'" />&nbsp;{{ strDate }}
    </span>
  </div>
  <div>
    <cbs-collapse :trigger="expanded">
      <cbs-paycal-table v-if="expanded" :period="period" :scenario="scenario" :ref-scenario="refScenario" />
    </cbs-collapse>
  </div>
  <cbs-debug v-if="isRoot" :context="this" />
</div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsCollapse from '@/cubus/components/collapsible/CbsCollapse.vue'
import CbsPaycalTable from '@/cubus/components/cbs-form/custom-forms/payment-calendar/CbsPaycalTable.vue'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsPaycalPeriod',
  components: {
    CbsPaycalTable,
    CbsCollapse,
    CbsDebug,
  },
  props: {
    period: {
      type: String,
      default: null,
    },
    scenario: {
      type: Object,
      default: null,
    },
    refScenario: {
      type: Array,
      default: null,
    },
  },
  setup() {
    return {
      isRoot: useCubus.isRoot(),
      monthNames: useCubus.monthNames(),
    }
  },
  data() {
    return {
      expanded: false,
      date: null,
      strDate: null,
    }
  },
  created() {
    // const [day, month, year] = this.period.split('.').map(Number) // Преобразуем в числа
    const [year, month, day] = this.period.split('-').map(Number) // Преобразуем в числа
    this.date = new Date(year, month - 1, day)
    this.strDate = `${this.monthNames[month - 1]} ${year}`
  },
}
</script>

<style scoped>

</style>
