<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!--<vuexy-logo />-->
        <cubus-logo />
        <h2 class="brand-text text-primary ml-1">
          <div style="color: darkred">
            Cubus
          </div>
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>

      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <span v-if="lang === 'ru'">Добро пожаловать в Cubus! 👋</span>
            <span v-else>Welcome to Cubus! 👋</span>
          </b-card-title>
          <b-card-text v-if="!showForgotSuccess" class="mb-2">
            <span v-if="lang === 'ru'">Восстановление пароля: введите новый пароль</span>
            <span v-else>Restore password: enter new password</span>
          </b-card-text>

          <!-- form -->
          <validation-observer
            id="forgotFormVO"
            ref="forgotFormSetNew"
            #default="{invalid}"
          >
            <b-form
              v-if="!showForgotSuccess"
              class="auth-login-form mt-2"
              @submit.prevent="forgot"
            >
            <b-form-group
              :label="lang === 'ru' ? ' Имя пользователя или е-мейл' : 'Username or Email'"
              label-for="login-email"
            >
                <b-form-input
                  id="login-email"
                  v-model="userEmail"
                  name="login-email"
                  disabled
                />
            </b-form-group>
            <b-form-group
              :label="lang === 'ru' ? 'Токен (проверьте почту)' : 'Token (check email)'"
              label-for="token_input"
            >
              <validation-provider
                #default="{ errors }"
                name="Token"
                vid="token"
                rules="required"
              >
                <b-form-input
                  id="token_input"
                  v-model="token"
                  name="token"
                  autofocus
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
              <!-- email -->
              <b-form-group
                :label="lang === 'ru' ? 'Новый пароль' : 'New password'"
                label-for="new-password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-form-input
                    id="password"
                    v-model="password"
                    :state="errors.length > 0 ? false:null"
                    name="password"
                    type="password"
                    placeholder="********"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-form-group
                :label="lang === 'ru' ? 'Повторите пароль' : 'Repeat password'"
                label-for="new-password2"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password2"
                  vid="password2"
                  rules="required"
                >
                  <b-form-input
                    id="password2"
                    v-model="password2"
                    :state="errors.length > 0 ? false:null"
                    name="password"
                    type="password"
                    placeholder="********"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid || password !== password2 || showForgotSuccess || loading"
              >
                <template v-if="loading">
                  <feather-icon icon="LoaderIcon" />
                  <span v-if="lang === 'ru'">Загрузка...</span>
                  <span v-else>Loading...</span>
                </template>
                <template v-else>
                  <span v-if="lang === 'ru'">Сохранить пароль</span>
                  <span v-else>Save password</span>
                </template>
              </b-button>
            </b-form>
          </validation-observer>

          <p
            v-if="showForgotSuccess"
            id="forgot-password-success"
            class="text-center mt-2"
          >
            <b-alert
              show
              variant="success"
              dismissible
              fade
            >
              <span v-if="lang === 'ru'">Новый пароль сохранен, вы можете попробовать его набрать</span>
              <span v-else>New password saved, you can try to type it</span>
            </b-alert>
          </p>

          <p class="text-center mt-2">
            <b-link :to="{name:'auth-login'}">
              <feather-icon icon="ChevronLeftIcon" />
              <span v-if="lang === 'ru'">Назад ко входу</span>
              <span v-else>Back to login</span>
            </b-link>
          </p>

          <div class="demo-inline-spacing">
            <b-form-radio v-model="lang" name="ru" value="ru">
              <b-img v-if="false"
                  :src="ruLocale().img"
                  height="14px"
                  width="22px"
                  alt="ru"
              />  RU
            </b-form-radio>
            <b-form-radio v-model="lang" name="en" value="en">
              <b-img v-if="false"
                  :src="enLocale().img"
                  height="14px"
                  width="22px"
                  alt="en"
              />  EN
            </b-form-radio>
          </div>

          <div class="small text-muted" style="margin-top:2rem;">
            release: {{ release }}
          </div>
          <cbs-debug v-if="false" :context="this" />
        </b-col>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import CubusLogo from '@/cubus/CubusLogo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle,
  BImg, BForm, BButton, BAlert, VBTooltip, BFormRadio,
} from 'bootstrap-vue'
import useJwt from '@/cubus/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import useCubus from "@/cubus/services/useCubus";

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    CbsDebug,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    // VuexyLogo,
    CubusLogo,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      showForgotSuccess: false,
      loading: false,
      status: false,
      userEmail: '',
      sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),

      // validation rules
      required,
      password: '',
      password2: '',
      token: '',
      release: '0000.0000.0000',
      lang: 'ru',
    }
  },
  created() {
    this.init()
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  setup() {
    /* eslint-disable global-require */
    const locales = [
      {
        locale: 'en',
        img: require('@/assets/images/flags/en.png'),
        name: 'English',
      },
      {
        locale: 'ru',
        img: require('@/assets/images/flags/ru.png'),
        name: 'Russian',
      },
    ]
    /* eslint-disable global-require */
    return {
      locales,
    }
  },
  methods: {
    init() {
      this.initRelease()
      // eslint-disable-next-line no-shadow
      const { email } = this.$route.query
      console.log('email', email)
      this.userEmail = email
    },
    forgot() {
      // console.log('Login.login')
      this.$refs.forgotFormSetNew.validate().then(success => {
        if (this.password !== this.password2) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.lang === 'ru' ? 'Пароли не совпадают' : 'Passwords do not match',
            },
          })
          return
        }
        const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/
        if (!passwordPattern.test(this.password)) {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: this.lang === 'ru' ? 'Пароль должен содержать цифры, заглавные и строчные буквы, спецсимволы и быть не менее 8 символов' : 'Password should contain numbers, capital and small letters, special symbols and be at least 8 symbols',
            },
          })
          return
        }
        if (success) {
          this.loading = true
          useJwt.forgot({
            password: this.password,
            token: this.token,
            email: this.userEmail,
          })
            .then(response => {
              this.loading = false
              console.log('forgot response', response)
              const message = response.data.message || 'Something went wrong'

              if (response.status === 200 && message.toUpperCase() === 'OK') {
                this.showForgotSuccess = true
              } else {
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `Something went wrong: ${response.status}`,
                    icon: 'CoffeeIcon',
                    variant: 'warning',
                    text: response.data.error || message,
                  },
                })
              }
            })
            .catch(error => {
              this.loading = false
              const message = (error.response && error.response.data && error.response.data.error)
                ? error.response.data.error
                : `Something went wrong: ${error.message}`
              console.log('login error', error)
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Error',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                  text: message,
                },
              })
            })
        }
      })
    },
    initRelease() {
      this.release = useCubus.release()
    },
    ruLocale() {
      return this.locales.find(l => l.locale === 'ru')
    },
    enLocale() {
      return this.locales.find(l => l.locale === 'en')
    },
  },
}
</script>

<style lang="scss">
@import 'cbs-page-auth';
</style>
