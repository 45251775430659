<template>
  <div>
    <div v-if="controlPanel">
      <cbs-dashboard v-for="(board, idx) in controlPanel.boards" :board="board" :key="idx" />
    </div>
    <div v-else>You don't have your control panel yet.</div>
    <cbs-debug v-if="isDev()" :context="this" />
  </div>
</template>

<script>
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsDashboard from '@/cubus/components/dashboard/CbsDashboard.vue'

export default {
  name: 'CbsControlPanel',
  components: {
    CbsDashboard,
    CbsDebug,
  },
  props: {},
  data() {
    return {
      controlPanel: null,
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loadControlPanel()
    },
    loadControlPanel() {
      useJwt.query({
        query: {
          method: 'controlpanel',
          param: {},
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadPanel(response.data.thread)
          }
        })
        .catch(error => {
          useCubus.toastError(this, error)
        })
    },
    threadPanel(thread) {
      useJwt.query({
        query: {
          method: 'thread',
          param: { threadname: thread },
        },
      })
        .then(response => {
          if (response.data.error) {
            useCubus.toastError(response.data.error)
          } else if (response.data.thread) {
            if (response.data.thread) {
              if (response.data.thread.status === 'done') {
                this.controlPanel = response.data.thread.result.controlPanel
              } else if (response.data.thread.status === 'error') {
                useCubus.toastError(this, response.data.thread.error)
              } else {
                this.delayPanel(thread)
              }
            } else {
              useCubus.toastError(this, 'Thread not found.')
            }
          } else {
            useCubus.toastError(this, 'Load control panel is aborted.')
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error.toString())
        })
    },
    delayPanel(thread) {
      setTimeout(() => this.threadPanel(thread), 500)
    },
    isDev() {
      return useCubus.isDeveloper()
    },
  },
}
</script>

<style scoped>

</style>
