<template>
  <div>
    <div v-if="ready" style="overflow: scroll; max-height: 75vh;">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th :id="`firstcol_${uuid}`" style="position: sticky; left: 0; top: 0; z-index: 4">#</th>
            <th :id="`secondcol_${uuid}`" style="position: sticky; top: 0; z-index: 4">Код</th>
            <th :id="`thirdcol_${uuid}`" style="position: sticky; top: 0; z-index: 4">Показатель</th>
            <th v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" style="position: sticky; top: 0; z-index: 3;">{{ role.name.value }}</th>
          </tr>
          <tr>
            <th class="th1" style="position: sticky; left: 0; z-index: 4; padding: 0.5rem 1rem 0.5rem 1rem;">
              <div style="display: flex;">
                <span @click="expandAll(true)" style="cursor: pointer;" title="Развернуть все">
                  <feather-icon icon="PlusSquareIcon" />
                </span>
                <span @click="expandAll(false)" style="cursor: pointer; margin-left: 1rem;" title="Свернуть все">
                  <feather-icon icon="MinusSquareIcon" />
                </span>
              </div>
            </th>
            <th class="th1 col2" style="position: sticky; z-index: 4;"></th>
            <th class="th1 col3" style="position: sticky; z-index: 4;"></th>
            <th v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" class="th1" style="position: sticky; z-index: 3; padding: 0.5rem 1rem 0.5rem 1rem;">
              <div style="display: flex;">
                <span @click="setAll(role, 'no')" style="cursor: pointer;" title="Блокировать все">
                  <feather-icon icon="XIcon" />
                </span>
                <span @click="setAll(role, 'ro')" style="cursor: pointer; margin-left: 1rem;" title="Просмотр все">
                  <feather-icon icon="EyeIcon" />
                </span>
                <span @click="setAll(role, 'rw')" style="cursor: pointer; margin-left: 1rem;" title="Редактирование все">
                  <feather-icon icon="Edit2Icon" />
                </span>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ind in indicators()" :key="'ind_' + ind.id.value">
            <td class="table-light" style="position: sticky; left: 0; z-index: 1;">
              <span v-if="ind.isfolder.value" @click="expandIndicator(ind, !ind.cbs_expanded)" style="cursor: pointer;">
                <feather-icon v-if="ind.cbs_expanded" icon="MinusSquareIcon" />
                <feather-icon v-if="!ind.cbs_expanded" icon="PlusSquareIcon" />
              </span>
            </td>
            <td class="table-light col2" style="position: sticky; left: 0; z-index: 1; padding: 0.5rem 1rem 0.5rem 1rem;">{{ ind.code.value }}</td>
            <td class="table-light col3"
                style="position: sticky; z-index: 1; padding-top: 0.5rem; padding-right: 0.5rem; padding-bottom: 0.5rem;"
                :style="{ paddingLeft: `${ ind.parentlevel.value * 1 }rem` }"
            >
              {{ ind.name.value }}
            </td>
            <td v-for="role in qryRole.recordset.records" :key="'role_' + role.id.value" style="padding: 0.5rem 1rem 0.5rem 1rem;">
              <cbs-ind-access-cell :record="getRecord(ind, role)" key-access="indicatoraccess"
                                   :ro-value="roValue" :rw-value="rwValue"
                                   @update="onUpdateCell(ind, role, $event)" />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <cbs-debug :context="this" />
  </div>
</template>

<script>
import CbsDebug from '@/cubus/components/debug/CbsDebug.vue'
import CbsIndAccessCell from '@/cubus/components/cbs-form/custom-forms/indicator-access/CbsIndAccessCell.vue'
import useJwt from '@/cubus/jwt/useJwt'
import useCubus from '@/cubus/services/useCubus'

export default {
  name: 'CbsIndicatorAccess',
  components: {
    CbsIndAccessCell,
    CbsDebug,
  },
  props: {
    objectFull: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      uuid: null,
      objAccess: null,
      objRole: null,
      objAccessType: null,
      qryAccess: null,
      qryRole: null,
      qryIndicator: null,
      qryAccessType: null,
      roValue: null,
      rwValue: null,
      firstCol: null,
      secondCol: null,
      ready: false,
    }
  },
  created() {
    this.uuid = useCubus.guid()
    this.init()
  },
  methods: {
    init() {
      this.loadObjAccess()
    },
    async loadObjAccess() {
      this.objAccess = await useCubus.loadObject(this, { objectsid: 'budgetindicatoraccess' })
      this.objRole = await useCubus.loadObject(this, { objectsid: 'role' })
      this.objAccessType = await useCubus.loadObject(this, { objectsid: 'accesstype' })
      this.loadQryAccess()
    },
    async loadQryAccess() {
      this.qryAccess = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'budgetindicatoraccess',
        pageSize: 100000,
      })
      const qryRoleFilter = {
        isactive: true,
        node: {
          isactive: true,
          oper: { sid: 'and' },
          nodes: [],
          conds: [
            {
              isactive: true,
              oper: { sid: 'notequal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: 'sid',
                },
                {
                  type: { sid: 'value' },
                  value: 'admin',
                },
              ],
            },
            {
              isactive: true,
              oper: { sid: 'notequal' },
              args: [
                {
                  type: { sid: 'entitysid' },
                  value: 'sid',
                },
                {
                  type: { sid: 'value' },
                  value: 'dev',
                },
              ],
            },
          ],
        },
      }
      this.qryRole = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'role',
        filter: qryRoleFilter,
        pageSize: 100000,
      })
      this.qryIndicator = await useCubus.loadQuery(this, {
        keymode: 'sid',
        objectsid: 'budgetindicator',
        filter: {
          isactive: true,
          node: {
            isactive: true,
            oper: { sid: 'and' },
            nodes: [],
            conds: [
              {
                isactive: true,
                oper: { sid: 'equal' },
                args: [
                  { type: { sid: 'entitysid' }, value: 'del' },
                  { type: { sid: 'value' }, value: 0 },
                ],
              },
            ],
          },
        },
        orderby: {
          isActive: true,
          sorts: [
            {
              isActive: true,
              oper: { sid: 'asc' },
              entity: { sid: 'code' },
            },
          ],
        },
        pageSize: 100000,
      })
      this.loadQryAccessType()
    },
    async loadQryAccessType() {
      this.qryAccessType = await useCubus.loadQuery(this, { objectsid: 'accesstype', pageSize: 100000 })
      this.roValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'ro').id.value
      this.rwValue = this.qryAccessType.recordset.records.find(r => r.sid.value === 'rw').id.value
      this.initIndicators()
      this.ready = true
      this.$nextTick(() => {
        this.stickTable()
      })
    },
    initIndicators() {
      this.qryIndicator.recordset.records.forEach(r => {
        if (r.isfolder.value) {
          this.$set(r, 'cbs_expanded', false)
        }
        if (r.parent.value === 0) {
          this.$set(r, 'cbs_visible', true)
        } else {
          this.$set(r, 'cbs_visible', false)
        }
      })
    },
    stickTable() {
      this.firstCol = document.getElementById(`firstcol_${this.uuid}`)
      this.secondCol = document.getElementById(`secondcol_${this.uuid}`)
      const w1 = this.firstCol.offsetWidth
      const w2 = this.secondCol.offsetWidth
      const h1 = this.firstCol.offsetHeight
      document.getElementById(`secondcol_${this.uuid}`).style.left = `${w1}px`
      document.getElementById(`thirdcol_${this.uuid}`).style.left = `${w1 + w2}px`
      document.querySelectorAll('.col2').forEach(cell => { const b = cell; b.style.left = `${w1}px` })
      document.querySelectorAll('.col3').forEach(cell => { const b = cell; b.style.left = `${w1 + w2}px` })
      document.querySelectorAll('.th1').forEach(cell => { const b = cell; b.style.top = `${h1}px` })
    },
    stick() {
      const c = document.getElementById(`firstcol_${this.uuid}`)
      return c ? c.offsetWidth : 0
    },
    getRecord(ind, role) {
      if (this.qryAccess) {
        const rec = this.qryAccess.recordset.records.find(r => r.budget_indicator.value === ind.id.value && r.role.value === role.id.value)
        if (rec) return rec
        const newrec = this.getNewRecord(ind, role)
        this.qryAccess.recordset.records.push(newrec)
        return newrec
      }
      return null
    },
    getNewRecord(ind, role) {
      const rec = {}
      this.qryAccess.columns.forEach(c => {
        if (c.key !== 'actions') {
          rec[c.entitysid] = { value: null, title: null }
        }
      })
      rec.del.value = false
      rec.budget_indicator = { value: ind.id.value, title: ind.name.value }
      rec.role = { value: role.id.value, title: role.name.value }
      return JSON.parse(JSON.stringify(rec))
    },
    onUpdateCell(ind, role, value) {
      const rec = this.getRecord(ind, role)
      if (value === 'no') {
        rec.indicatoraccess.value = null
        rec.indicatoraccess.title = null
      } else {
        const acc = this.qryAccessType.recordset.records.find(r => r.sid.value === value)
        rec.indicatoraccess.value = acc.id.value
        rec.indicatoraccess.title = acc.name.value
      }
      rec.indicatoraccess.status = 'changed'
      this.saveRecord(rec, 'indicatorAccess')
      if (ind.isfolder.value) {
        this.qryIndicator.recordset.records.filter(r => r.parent.value === ind.id.value).forEach(r => {
          this.onUpdateCell(r, role, value)
        })
      }
    },
    /*
    saveRecord_v1(record, caller) {
      useJwt.query({
        token: localStorage.getItem(useJwt.jwtConfig.storageCubusTokenKeyName),
        query: {
          method: 'saverecord',
          param: {
            objectid: this.objAccess.object.id,
            record,
          },
        },
      })
        .then(response => {
          console.log('save record response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread) {
            this.threadSaveRecord(response.data.thread, record, caller)
          } else {
            useCubus.toastError(this, 'No thread name provided.')
          }
        })
        .catch(error => {
          console.log('save record error', error)
        })
    },
    */
    async saveRecord(record) {
      try {
        const saved = await useCubus.saveRecord(this, { keymode: 'sid', objectsid: 'budgetindicatoraccess', record })
        this.afterSave(record, saved)
      } catch (e) { console.error(e) }
    },
    /*
    threadSaveRecord(thread, record, caller) {
      useJwt.query({ query: { method: 'thread', param: { threadname: thread } } })
        .then(response => {
          console.log('threadSaveRecord response', response)
          if (response.data.error) {
            useCubus.toastError(this, response.data.error)
          } else if (response.data.thread.status === 'error') {
            useCubus.toastError(this, response.data.thread.error)
          } else if (response.data.thread.status === 'done') {
            this.afterSave(record, response.data.thread.result.record)
          } else {
            this.delaySaveRecord(thread, record, caller)
          }
        })
        .catch(error => {
          console.log('thread error', error)
          useCubus.toastError(this, error)
        })
    },
    delaySaveRecord(thread, record, caller) {
      setTimeout(() => this.threadSaveRecord(thread, record, caller), 200)
    },
    */
    afterSave(initRec, savedRec) {
      const s = savedRec
      const i = initRec
      s.status = 'saved'
      this.qryAccess.columns.forEach(fld => {
        i[fld.entitysid] = savedRec[fld.entitysid]
      })
      i.status = 'saved'
    },
    indicators() {
      return this.qryIndicator.recordset.records.filter(r => r.cbs_visible)
    },
    expandIndicator(ind, expanded) {
      const i = ind
      i.cbs_expanded = expanded
      this.qryIndicator.recordset.records.filter(r => r.parent.value === ind.id.value).forEach(r => {
        this.setVisible(r, expanded)
      })
      this.$nextTick(() => {
        this.stickTable()
      })
    },
    setVisible(ind, visible) {
      const i = ind
      i.cbs_visible = visible
      if (ind.isfolder.value && ind.cbs_expanded) {
        this.qryIndicator.recordset.records.filter(r => r.parent.value === ind.id.value).forEach(r => {
          this.setVisible(r, visible)
        })
      }
    },
    expandAll(expand) {
      this.qryIndicator.recordset.records.forEach(r => {
        this.expandIndicator(r, expand)
      })
    },
    setAll(role, access) {
      this.qryIndicator.recordset.records.filter(r => r.parent.value === null || r.parent.value === 0).forEach(ind => {
        this.onUpdateCell(ind, role, access)
      })
    },
  },
}
</script>

<style scoped>

</style>
